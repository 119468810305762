import { getRequest, postRequest } from '@/utils/http'
import { questionsBankRequest } from '@/utils/http/questionsBank-service'

// 获取教师分享到平台的分享记录
export const getOfficialTeacherCoursewareShareRecord = (params: any): any =>
  getRequest('/official/teacher-courseware-share-records', params)

// 平台分享通过
export const auditOfficialTeacherCoursewarePass = (id: string, params: any): any =>
  postRequest(`/official/teacher-courseware-share-record/${id}/audit-pass`, params)

// 平台分享不通过
export const auditOfficialTeacherCoursewareReject = (id: string, params: any): any =>
  postRequest(`/official/teacher-courseware-share-record/${id}/audit-reject`, params)

// 根据分享记录id获取课件信息
export const getOfficialTeacherCoursewareInfoByShareRecordId = (id: string): any =>
  getRequest(`/official/teacher-courseware-share-record/${id}`)

// 获取教师分享到学校的
export const getSchoolTeacherCoursewareShareRecord = (params: any): any =>
  getRequest('/school/teacher-courseware-share-records', params)

// 学校分享通过
export const auditSchoolTeacherCoursewarePass = (id: string, params: any): any =>
  postRequest(`/school/teacher-courseware-share-record/${id}/audit-pass`, params)

// 学校分享不通过
export const auditSchoolTeacherCoursewareReject = (id: string, params: any): any =>
  postRequest(`/school/teacher-courseware-share-record/${id}/audit-reject`, params)

// 根据分享记录id获取课件信息
export const getSchoolTeacherCoursewareInfoByShareRecordId = (id: string): any =>
  getRequest(`/school/teacher-courseware-share-record/${id}`)

// 局端教师分享到学校的
export const getInstitutionTeacherCoursewareShareRecord = (params: any): any =>
  getRequest('/institution/teacher-courseware-share-records', params)

// 局端分享通过
export const auditInstitutionTeacherCoursewarePass = (id: string, params: any): any =>
  postRequest(`/institution/teacher-courseware-share-record/${id}/audit-pass`, params)

// 局端分享不通过
export const auditInstitutionTeacherCoursewareReject = (id: string, params: any): any =>
  postRequest(`/institution/teacher-courseware-share-record/${id}/audit-reject`, params)

// 根据分享记录id获取课件信息
export const getInstitutionTeacherCoursewareInfoByShareRecordId = (id: string): any =>
  getRequest(`/institution/teacher-courseware-share-record/${id}`)

// 根据题目组id获取题目组信息
export const getQGroupInfo = (id: string): any => questionsBankRequest('GET', `/teacher/q-group/${id}/questions`, {})

// 根据题目组id获取题目组广场信息
export const getQGroupSquareInfo = (id: string): any => questionsBankRequest('GET', `/official/q-group/${id}/questions`, {})
