<template>
  <a-card>
    <template #title>
      <span>{{ title }}</span>
    </template>
    <template #extra>
      <a-button type="primary" @click="handleSave" style="margin-right: 20px" v-if="!isVersionDetail && !readOnly">保存课件</a-button>
      <a @click="handleGoBack" v-if="readOnly || !pageState.isEdit">返回</a>
      <a-popconfirm
        v-else
        placement="bottomRight"
        title="有尚未保存的内容，确定要离开吗？"
        ok-text="确定"
        cancel-text="取消"
        @confirm="handleGoBack"
      >
        <a>返回</a>
      </a-popconfirm>
    </template>
    <div>
      <a-row style="width: 100%" :gutter="48">
        <a-col :md="8" :sm="24">
          <a-form-item label="名称">
            <a-input
              v-model:value="formState.name"
              :disabled="readOnly"
              @change="() => pageState.isEdit = true"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="标签">
            <a-select
              @change="() => pageState.isEdit = true"
              :disabled="readOnly"
              v-model:value="formState.tags"
              mode="multiple"
              placeholder="可选择标签"
              :options="formOpts.tags"
              @search="handleTagSearch"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="教材章节">
            <a @click="changeBook">{{formOpts.bookOpts.showName}}</a>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="width: 100%" :gutter="48">
        <a-col :md="8" :sm="24" v-if="!isVersionDetail">
          <a-form-item label="分享模式" >
            <a-select
              @change="() => pageState.isEdit = true"
              :disabled="readOnly"
              v-model:value="formState.share_mode"
              :options="formOpts.share_mode"
            />
          </a-form-item>
        </a-col>
        <a-col :md="16" :sm="24" v-if="isRelated">
          <a-form-item label="关联课件" >
            <a-tag :closable="!readOnly" @close="cancelRelate(tag)" v-for="tag in formState.relate_coursewares" :key="tag.id">{{tag.name}}</a-tag>
            <a-button v-if="!readOnly" size="small" @click="handleRelateCoursewareModalOpen">+添加</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </a-card>
  <select-book-modal
    ref="modalRef"
    :type="1"
    v-model:visible="pageState.selectBookModalVisible"
    @ok="handleSelectBookSection"
  />
  <select-relate-courseware-modal
    :visible="pageState.selectRelateCoursewareModalVisible"
    :relate-coursewares="formState?.relate_coursewares || []"
    @ok="handleSelectRelateCoursewareSection"
    @onCancel="handleRelateCoursewareModalCancel"
    @onClear="handleClearRelatedCourseware"
  />
</template>

<script>
import { toRefs, reactive, computed, onBeforeMount, watch } from 'vue'
import { useRouter } from 'vue-router'
import { debounce } from '@/utils/common'
import { getTags } from '@/services/common'
import SelectBookModal from '@/views/songs/components/SelectBookModal'
import SelectRelateCoursewareModal from './SelectRelateCoursewareModal.vue'
import { shareModeMap } from '@/utils/dataMap'
import { getOfficialBookInfo } from '@/views/teaching-material/preview/service'
import { message } from 'ant-design-vue'

export default {
  name: 'CoursewareForm',
  components: {
    SelectBookModal,
    SelectRelateCoursewareModal
  },
  props: {
    title: {
      type: String,
      default: '新建课件'
    },
    current: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    isVersionDetail: {
      type: Boolean,
      default: false
    },
    isRelated: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isOnPPT: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onSave', 'cancelRelate', 'updateBookSection', 'onBeforeGoBack'],
  setup (props, { emit }) {
    const router = useRouter()
    const pageState = reactive({
      selectBookModalVisible: false,
      selectRelateCoursewareModalVisible: false,
      isEdit: false
    })
    const formState = computed({
      get: () => props.current,
      set: val => {
        emit('update:current', val)
      }
    })

    const formOpts = reactive({
      subject: [],
      share_mode: Object.keys(shareModeMap).map(i => {
        return { value: parseInt(i), label: shareModeMap[i].name }
      }),
      gradePeriod: [],
      pressVersion: [],
      tags: [],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })

    if (!formState.value.share_mode) {
      formState.value.share_mode = 0
    }

    watch(() => props.current.bookShowName, () => {
      formOpts.bookOpts.showName = props.current.bookShowName ?? formOpts.defaultBookOpts.showName
    })

    watch(() => props.isEdit, (val) => {
      pageState.isEdit = val
    })

    const tagParams = reactive({
      pageSize: 50,
      page: 1,
      keyword: ''
    })

    const getTagsOptions = debounce(async () => {
      const params = { per_page: tagParams.pageSize, current: tagParams.page, keyword: tagParams.keyword }
      const getTagsService = getTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const handleTagSearch = v => {
      tagParams.page = 1
      tagParams.keyword = v
      getTagsOptions()
    }

    const handleSave = () => {
      if (formState.value.relate_coursewares && Object.keys(formState.value.relate_coursewares).length !== 0) {
        const relate_courseware_ids = formState.value.relate_coursewares.map(item => item.id)
        emit('onSave', relate_courseware_ids)
      } else {
        emit('onSave')
      }
    }

    const handleSelectRelateCoursewareSection = (isRelate) => {
      pageState.selectRelateCoursewareModalVisible = false
      const relate_ppts = JSON.parse(localStorage.getItem('relate_ppts'))
      formState.value.relate_coursewares = relate_ppts
      if (isRelate) {
        message.success('关联成功')
        pageState.isEdit = true
      }
    }

    const handleClearRelatedCourseware = () => {
      formState.value.relate_coursewares = {}
      pageState.isEdit = true
    }

    const cancelRelate = (tag) => {
      pageState.isEdit = true
      formState.value.relate_coursewares = formState.value.relate_coursewares.filter((list) => list.id !== tag.id)
      const relate_ppts = formState.value.relate_coursewares
      localStorage.setItem('relate_ppts', JSON.stringify(relate_ppts))
    }

    const handleSelectBookSection = async (value) => {
      pageState.selectBookModalVisible = false
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        formState.value.book_section_id = value.values
        if (props.title === '新建课件') {
          const { share_mode } = await getOfficialBookInfo(value.values[0])
          formState.value.share_mode = share_mode || 0
        }
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
    }

    console.log('formState', formState)

    const getOptions = () => {
      getTagsOptions()
    }

    const changeBook = () => {
      if (!props.readOnly) {
        pageState.selectBookModalVisible = true
      }
      pageState.isEdit = true
    }

    const handleRelateCoursewareModalOpen = () => {
      pageState.selectRelateCoursewareModalVisible = true
    }

    const handleRelateCoursewareModalCancel = () => {
      pageState.selectRelateCoursewareModalVisible = false
    }

    const handleGoBack = () => {
      if (props.isOnPPT) {
        emit('onBeforeGoBack')
      } else {
        history.go(-1)
      }
      localStorage.removeItem('relate_ppts')
    }

    onBeforeMount(async () => {
      await getOptions()
      localStorage.removeItem('relate_ppts')
    })

    return {
      handleGoBack,
      changeBook,
      cancelRelate,
      pageState,
      formOpts,
      formState,
      history,
      handleTagSearch,
      ...toRefs(props),
      handleSave,
      handleSelectBookSection,
      handleSelectRelateCoursewareSection,
      handleClearRelatedCourseware,
      handleRelateCoursewareModalOpen,
      handleRelateCoursewareModalCancel
    }
  }
}
</script>

<style lang="less" scoped>
.ant-row,.ant-form-item{
  margin-bottom: 5px;
}
</style>
