<template>
  <courseware-form
    :is-version-detail="false"
    title="预览题目组"
    v-model:current="pageState.current"
    :readOnly="true"

  />
  <div class="content">
    <QGroup :qgroupInfo="qGroupInfo"></QGroup>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import QGroup from '@/components/common/QGroup.vue'
import CoursewareForm from '@/views/courseware/components/CoursewareForm'
import { ref, onMounted, reactive } from 'vue'
import {
  getQGroupInfo,
  getQGroupSquareInfo
} from '@/services/audit-management'

export default {
  name: 'QGroupEdit',
  components: {
    QGroup,
    CoursewareForm
  },
  setup() {
    const route = useRoute()
    const qGroupInfo = ref({})
    const pageState = reactive({
      current: {}
    })
    onMounted(async() => {
      try {
        if (route?.query?.isFromOfficial === 'true') {
          qGroupInfo.value = await getQGroupSquareInfo(parseInt(route.params.id))
        } else {
          qGroupInfo.value = await getQGroupInfo(parseInt(route.params.id))
        }
        pageState.current.name = route?.query?.name || '未命名题目组'
      } catch (err) {
        console.error('获取题目组详情失败：', err?.message)
      }
    })
    return { qGroupInfo, pageState }
  }
}
</script>

<style scoped>
.content{
  width:100%;
  /* height: 100%; */
}
</style>
